import PropTypes from 'prop-types';
import React, { useRef, useEffect, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Chart } from 'chart.js/auto';
import { formatDataTestName } from 'client-lib/src/lib/utils/helpers';
import Widget from '../../elements/Widget/Widget';
import COLORS from '../../styles/colors';
import THEMES from '../../styles/themes/app';

const GraphContainer = styled.div`
  flex-direction: row;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PIE_CHART_COLORS = [
  COLORS.PK_CLASSIC.PIE_CHART_BLUE,
  COLORS.PK_CLASSIC.PIE_CHART_ORANGE,
  COLORS.PK_CLASSIC.PIE_CHART_YELLOW,
  COLORS.PK_CLASSIC.PIE_CHART_GREEN,
  COLORS.PK_CLASSIC.PIE_CHART_RED,
];

/* 
  DoughnutWidget: 
    props: {
      doughnutWidgetData: objects array to render both the chart + labels ---> [ { value: X, pieColor: 'red', label: 'XXX'} ]
      total: numeric value showing on top of the card
      title: string header showing on top of the card 
    }
*/
const DoughnutWidget = ({
  doughnutWidgetData,
  total,
  title,
  emptyDoughnutMessage,
  breakpointWidth,
}) => {
  const styledTheme = useContext(ThemeContext);

  const doughnutWidgetDataValues = doughnutWidgetData.map(
    (element) => element.value
  );
  const doughnutWidgetDataLabels = doughnutWidgetData.map(
    (element) => element.label
  );
  const doughnutWidgetDataTooltips = doughnutWidgetData.map(
    (element) => element?.otherProps?.toolTip
  );

  const data = {
    labels: doughnutWidgetDataLabels,
    datasets: [
      {
        data: doughnutWidgetDataValues,
        // Slice the colors for PIE CHART to just the quantity needed for the data set
        backgroundColor: PIE_CHART_COLORS.slice(
          0,
          doughnutWidgetDataValues.length
        ),
      },
    ],
  };

  // This adds a data placeholder for gray circle if there is no data to dislay. It will be overwritten by any new subscription changes that get pushed
  data.datasets.forEach((dataset) => {
    if (dataset.data.every((el) => !(el > 0))) {
      dataset.backgroundColor.push(
        THEMES.BACKGROUND_SECONDARY({ theme: styledTheme })
      );
      dataset.data.push(1);
    }
  });

  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');
    let myDoughnutChart;

    if (data?.datasets?.length) {
      myDoughnutChart = new Chart(ctx, {
        type: 'doughnut',
        data,
        options: {
          events: ['mouseover', 'mousemove'],
          animation: false,
          aspectRatio: 2,
          plugins: {
            legend: {
              display: true,
              onHover: handleHover,
              onLeave: handleLeave,
              position: 'right',
              labels: {
                boxWidth: 15,
                boxHeight: 16,
                font: {
                  size: 14,
                  family: "'Barlow', Sans-Serif",
                  weight: '500',
                },
                color: THEMES.FOREGROUND_HIGH({ theme: styledTheme }),
              },
            },
            tooltip: {
              callbacks: {
                label(tooltipItems) {
                  // Prevent items with undefined labels from showing tooltips
                  if (!tooltipItems?.label) {
                    return emptyDoughnutMessage;
                  }
                  return doughnutWidgetDataTooltips[tooltipItems.dataIndex];
                },
              },
              padding: 10,
              boxPadding: 4,
              bodySpacing: 4,
              bodyFont: {
                size: 14,
                family: "'Barlow', Sans-Serif",
              },
              titleFont: {
                size: 14,
                family: "'Barlow', Sans-Serif",
              },
            },
          },
        },
      });
    }

    function handleHover(ev, item, legend) {
      const activeElement = {
        datasetIndex: 0,
        index: item.index,
      };
      legend.chart.tooltip.setActiveElements([activeElement]);
      legend.chart.update();
    }

    function handleLeave(ev, item, legend) {
      legend.chart.tooltip.setActiveElements([]);
      legend.chart.update();
    }

    return () => {
      if (myDoughnutChart) {
        myDoughnutChart.destroy();
      }
    };
  }, [data]);

  return (
    <Widget title={title} total={total} breakpointWidth={breakpointWidth}>
      <GraphContainer>
        <div
          style={{ width: '300px' }}
          data-testid={`doughnut-widget-${formatDataTestName(title)}`}
        >
          <canvas ref={chartRef} />
        </div>
      </GraphContainer>
    </Widget>
  );
};

DoughnutWidget.propTypes = {
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  doughnutWidgetData: PropTypes.arrayOf(Object).isRequired,
  emptyDoughnutMessage: PropTypes.string.isRequired,
  breakpointWidth: PropTypes.string,
};

DoughnutWidget.defaultProps = {
  breakpointWidth: undefined,
};

const areEqual = (prevProps, nextProps) => prevProps.total === nextProps.total;

export default React.memo(DoughnutWidget, areEqual);
